<template src="./activityNewAssignments.html"></template>

<script>
import PortalFooter from "../../../Components/PortalFooter";
import SubjectsRepository from "../../../Repository/Subjects";
import SemesterRepository from "../../../Repository/Semester";
import NewExamRepository from "../../../Repository/NewExam";
import showStatus from '../../../NetworkManager/showStatus'
import { convertDate, timeStringConverter } from "../../../Services/Utils/DateTimeFormatter"

export default {
  name: "activityNewAssignments",
  components: {
    PortalFooter
  },
  props: [
    'prop_department',
    'prop_course',
    'prop_selectedSubject',
    'prop_typeDivGrpBatch',
    'prop_selectedDivision',
    'prop_selectedBatch',
    'prop_selectedGroup'
  ],

  data() {
    return {
      blue: "#050D92",
      backgroundColor: "#E7E7FFCF",
      orange: "#FF4F1F",
      downArrow: "mdi-chevron-down",
      upArrow: "mdi-arrow-up",
      examsLoaded: true,
      isMobileView: false,
      assignedExam: [],
      selectedExamType: '',
      assignedExamDetails: {},
      academicDataTree: {},
      subjectId: [],
      filteredSubjectId: [],
      courseYears: [],
      subjectNamesById: [],
      courseName: [],
      departments: [],
      divisionName: [],
      semestersList: [],
      divisions: [],
      departmentName: [],
      selectedDivision: "",
      course: "",
      department: "",
      allExamsDetails: [],
      selectedSemester: '',
      selectedSubject: null,
      facultySubjects: [],
      typeForDivOrGrpOrBatch: ['Division', 'Group', 'Batch'],
      typeDivGrpBatch: '',
      batches: [],
      selectedBatch: {},
      subjectGroups: [],
      selectedGroup: {},
      allExams: [],
      examTableHeaders: [
        {
          text: 'Name',
          value: 'title'
        },
        {
          text: 'Allowed File types',
          value: 'allowedFileTypes'
        },
        {
          text: 'Published On',
          value: 'convertedPublishedDate'
        },
        {
          text: 'Due',
          value: 'convertedDueDate'
        },
        {
          text: 'Time',
          value: 'convertedTime'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center'
        }
      ],
      filteredExams: [],
      subjectExams: [],
      showModal: false,
      examToDelete: null,
      loading: false
    };
  },
  async created() {
    this.$store.commit("liveData/set_selectedActivityName", `My Exam`);
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.newExamRepositoryInstance = new NewExamRepository(this);
    this.user = this.$store.getters["user/get_user"];
    this.userData = this.$store.getters["user/get_userData"];
    // console.log('userId', this.userData)
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.subjectsRepositoryInstance = new SubjectsRepository(this);
    await this.getSemestersList();
    this.subjectId = this.userData.subjects.map((item) => item.subjectId);
    this.departments = [
      ...new Set(this.userData.subjects.map((item) => item.department))
    ];
    if (this.prop_department) {
      this.department = this.prop_department
      await this.selectDepartment()
    }
    if (this.prop_course) {
      this.course = this.prop_course
      await this.selectCourseYear()
    }
    if (this.prop_selectedSubject) {
      this.selectedSubject = this.prop_selectedSubject
      await this.getGroupsOfSubjects()
    }
    if (this.prop_typeDivGrpBatch) this.typeDivGrpBatch = this.prop_typeDivGrpBatch
    if (this.prop_selectedDivision) {
      this.selectedDivision = this.prop_selectedDivision
      await this.filterDivAssignments()
    }
    if (this.prop_selectedBatch) {
      await this.getDivisionBatches()
      this.selectedBatch = this.prop_selectedBatch
      await this.filterBatchAssignments()
    }
    if (this.prop_selectedGroup?.groupId) {
      this.selectedGroup = this.prop_selectedGroup
      await this.filterGroupAssignments()
    }
  },
  methods: {
    checkIfExamRoute() {
      if (this.selectedExamType === 'Exams') {
        this.$router.push({
          name: 'activityMyExam'
        })
      }
    },
    async getExams() {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        department: this.department,
        courseYear: this.course
      }
      this.allExams = await this.newExamRepositoryInstance.getExams(objToPush)
      this.allExams.map((exam) => {
        exam.convertedPublishedDate = convertDate(exam.publishDate)
        exam.convertedTime = timeStringConverter(exam.dueTime)
        exam.convertedDueDate = convertDate(exam.dueDate)
      })
      this.loading = false
    },
    async getSemestersList() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId
        };
        this.semestersList =
          await this.semesterRepositoryInstance.getDivisionsOfASemester(
            objToPush
          );
      } catch (e) {
        console.error("e", e);
      }
    },
    selectDepartment() {
      this.selectedCourseYear = "";
      this.course = "";
      this.typeDivGrpBatch = ""
      this.selectedDivision = "";
      this.assignedExam = []
      this.facultySubjects = []
      this.selectedSubject = null
      this.typeDivGrpBatch = ''
      this.subjectGroups = []
      this.selectedGroup = {}
      this.filteredExams = []
      this.courseYears = this.semestersList.filter(item => item.department === this.department).map(data => data.courseYear)
      this.$store.commit("liveData/set_examDepartment", this.department);
    },
    async selectCourseYear() {
      this.loading = true
      this.selectedDivision = "";
      this.typeDivGrpBatch = ""
      this.assignedExam = []
      this.facultySubjects = []
      this.selectedSubject = null
      this.typeDivGrpBatch = ''
      this.subjectGroups = []
      this.selectedGroup = {}
      this.filteredExams = []
      this.divisions = this.semestersList.filter(item => item.department === this.department && item.courseYear === this.course).map(data => data.division)
      // console.log('this.semestersList', this.semestersList, this.divisions)
      this.$store.commit("liveData/set_examCourseYear", this.course);
      try {
        const facultySubjects = await this.subjectsRepositoryInstance.getSubjectsTaughtByAFaculty({
          uId: this.userData.uId,
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.department,
          courseYear: this.course
        })
        this.facultySubjects = facultySubjects.subjects
      } catch (error) {
        console.error(error);
      }
      await this.getExams()
    },
    typeSelected() {
      this.selectedGroup = {}
      this.selectedDivision = ""
      this.selectedBatch = ''
      this.filteredExams = []
    },
    filterSubjectExams() {
      // this.allExams =
      this.subjectExams = this.allExams.filter(exam => exam.subjectIds?.includes(this.selectedSubject.subjectId))
    },
    filterDivAssignments() {
      this.filteredExams = []
      if (this.typeDivGrpBatch === 'Division') {
        this.filteredExams = this.subjectExams.filter(exam =>
          (exam?.division === this.selectedDivision) && (exam.uId === this.userData.uId || exam.uId === undefined) && (!exam.batch || exam.batch === '')
        ).reverse();
      }
    },
    filterGroupAssignments() {
      this.filteredExams = []
      if (this.typeDivGrpBatch === 'Group') {
        this.filteredExams = this.subjectExams.filter(exam => (exam?.assignedGroupForSubject?.groupName === this.selectedGroup.groupName) && (exam.uId === this.userData.uId || exam.uId === undefined)).reverse()
      }
    },
    filterBatchAssignments() {
      this.filteredExams = []
      if (this.typeDivGrpBatch === 'Batch') {
        this.filteredExams = this.subjectExams.filter(exam => (exam?.batch === this.selectedBatch.batchName && exam?.division === this.selectedDivision) && (exam.uId === this.userData.uId || exam.uId === undefined)).reverse()
      }
    },
    async getDivisionBatches() {
      this.selectedBatch = ''
      this.filteredExams = []
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.department,
          courseYear: this.course,
          division: this.selectedDivision
        };
        this.batches =
          await this.semesterRepositoryInstance.getBatchesDetails(
            objToPush
          );
      } catch (e) {
        console.error("e", e);
      }
    },
    getGroupsOfSubjects() {
      this.typeDivGrpBatch = ''
      this.filteredExams = []
      this.subjectGroups = this.selectedSubject.groupForSubject
      this.filterSubjectExams()
    },
    goToCreateExam(exam) {
      const objToPush = {
        prop_department: this.department,
        prop_course: this.course,
        prop_selectedSubject: this.selectedSubject,
        prop_typeDivGrpBatch: this.typeDivGrpBatch,
        prop_selectedDivision: this.selectedDivision,
        prop_selectedBatch: this.selectedBatch,
        prop_selectedGroup: this.selectedGroup
      }
      if (exam) {
        objToPush['prop_exam'] = exam
      }
      this.$router.push({
        name: 'activityNewCreateExam',
        params: objToPush
      })
    },
    async goToDeleteExam() {
      const exam = this.examToDelete
      try {
        const status = await this.newExamRepositoryInstance.deleteExam({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          examId: exam.examId
        })
        showStatus("Successfully deleted the assignment.", 2000, 'success', this);
        console.log('status', status)
        await this.getExams()
        this.filterSubjectExams()
        if (this.typeDivGrpBatch === 'Division') {
          this.filterDivAssignments()
        }
        if (this.typeDivGrpBatch === 'Group') {
          this.filterGroupAssignments()
        }
        if (this.typeDivGrpBatch === 'Batch') {
          this.filterBatchAssignments()
        }
      } catch (e) {
        console.error("e", e);
      }
      this.showModal = false
    }
  }
};
</script>

<style scoped src="./activityNewAssignments.css"></style>
